var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Create purchase","show":_vm.show},on:{"update:show":function($event){return _vm.$emit('update:show', $event)},"click-create":_vm.create},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('TButton',{staticClass:"ml-auto",attrs:{"options":{ disabled: _vm.loading },"content":"Create","icon":"cil-plus"},on:{"click":_vm.create}})]},proxy:true}])},[_c('SSelectSupplier',{staticClass:"mb-3",attrs:{"label":"Supplier","value":_vm.purchase.supplier_id},on:{"update:value":[function($event){return _vm.$set(_vm.purchase, "supplier_id", $event)},function($event){_vm.purchase.supplier_id = $event}]}}),_c('TInputMoney',{staticClass:"mb-3",attrs:{"label":"Additional cost","append":_vm.purchase.currency_id},on:{"update:value":function($event){_vm.purchase.additional_cost = $event}}}),_c('CRow',[_c('CCol',{attrs:{"col":"6"}},[_c('TInputDateTime',{staticClass:"mb-3",attrs:{"label":"Payment due date","value":_vm.item.payment_due_date},on:{"update:value":function($event){_vm.item.payment_due_date = $event}}})],1),_c('CCol',{attrs:{"col":"6"}},[_c('TInputDateTime',{staticClass:"mb-3",attrs:{"label":"Expected delivery","value":_vm.item.expected_delivery},on:{"update:value":function($event){_vm.item.expected_delivery = $event}}})],1)],1),_c('hr'),_vm._l((_vm.orderItem),function(item){return _c('div',{key:item.id,staticClass:"border-primary rounded mb-2 p-2"},[_c('SCardProductItems',{attrs:{"item":item.product}}),_c('TTableAsForm',{staticClass:"mt-2",attrs:{"data":item,"fields":_vm.itemFields,"splitLeft":6,"addRowClasses":[]},scopedSlots:_vm._u([{key:"price",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":item.currency_id}})]}},{key:"tax_percent",fn:function(ref){
var value = ref.value;
return [_c('TMessagePercent',{attrs:{"value":value}})]}},{key:"quantity",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value}})]}},{key:"balance",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":item.currency_id}})]}}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }